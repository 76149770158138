<template>
  <div class="font-app antialiased text-app-green-darkest">
    <component :is="layout">
      <router-view :key="$route.path"/>
    </component>

    <messages/>
  </div>
</template>

<script>
import config from '@/./config';
import Messages from '@/components/Messages.vue';

export default {
  components: {
    Messages,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  metaInfo() {
    return {
      title: 'Default',
      titleTemplate: this.$t('meta_title_template', { name: config.app.name }),
    };
  },
};
</script>
