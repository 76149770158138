import {
  CLEAR_ALL_VALIDATION_ERRORS,
  CLEAR_FIELD_VALIDATION_ERRORS,
  CLEAR_VALIDATION_ERRORS,
  SET_VALIDATION_ERRORS,
} from '@/store/mutation-types';
import Errors from '@/utilities/errors';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    validation: {}, // Object key == namespace (not to be confused with a Vuex namespace!).
  },
  mutations: {
    [SET_VALIDATION_ERRORS](state, { namespace, errors }) {
      // Convert Laravel's dot notation to a nested object.
      const nestedErrors = {};
      // eslint-disable-next-line no-shadow
      _.forEach(errors, (errors, field) => {
        _.set(nestedErrors, field, errors);
      });

      // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
      state.validation = {
        ...state.validation,
        [namespace]: nestedErrors,
      };
    },
    [CLEAR_VALIDATION_ERRORS](state, { namespace }) {
      state.validation = _.omit(state.validation, [
        namespace,
      ]);
    },
    [CLEAR_FIELD_VALIDATION_ERRORS](state, { namespace, field }) {
      let formErrors = _.get(state.validation, namespace, []);
      formErrors = _.omit(formErrors, [
        field,
      ]);

      state.validation = {
        ...state.validation,
        [namespace]: formErrors,
      };
    },
    [CLEAR_ALL_VALIDATION_ERRORS](state) {
      state.validation = {};
    },
  },
  actions: {
    setValidationErrors({ commit }, { namespace, errors }) {
      commit(SET_VALIDATION_ERRORS, {
        namespace,
        errors,
      });
    },
    clearValidationErrors({ commit }, { namespace }) {
      commit(CLEAR_VALIDATION_ERRORS, {
        namespace,
      });
    },
    clearFieldValidationErrors({ commit }, { namespace, field }) {
      commit(CLEAR_FIELD_VALIDATION_ERRORS, {
        namespace,
        field,
      });
    },
    clearAllValidationErrors({ commit }) {
      commit(CLEAR_ALL_VALIDATION_ERRORS);
    },
  },
  getters: {
    getErrors: (state) => (namespace) => new Errors(_.get(state.validation, namespace, [])),
    // This supports retrieval via dot notation.
    getFieldErrors: (state) => (namespace, field) => _.get(state.validation, `${namespace}.${field}`, false),
  },
};
