<template>
  <nav class="nav" @click="$emit('navClicked')">
    <nav-link
      :label="$t('dashboard')"
      :to="{ name: 'dashboard' }"
      svg="home"
    />
    <nav-link
      :label="$t('organisations')"
      :to="{ name: 'master_organisations' }"
      svg="office-building"
      v-if="$can('master')"
    />
    <template v-if="user.organisation_id">
      <nav-link
        :label="$t('assets')"
        :to="{ name: 'assets' }"
        svg="collection"
        v-if="$can('manage_assets')"
      />
      <nav-link
        :label="$t('categories')"
        :to="{ name: 'asset_categories' }"
        svg="folder"
        v-if="$can('manage_asset_categories')"
      />
      <nav-link
        :label="$t('reports')"
        :to="{ name: 'asset_reports' }"
        svg="document-report"
        v-if="$can('manage_asset_reports')"
      >
        <badge
          :label="actionReqCount"
          :theme="actionReqTheme"
          class="ml-auto text-xs"
          v-if="actionReqCount !== null"
        />
      </nav-link>
      <nav-link
        :label="$t('users')"
        :to="{ name: 'users' }"
        svg="users"
        v-if="$can('manage_users')"
      />
      <nav-link
        :label="$t('roles')"
        :to="{ name: 'roles' }"
        svg="lock-open"
        v-if="$can('manage_roles')"
      />
      <nav-link
        :label="$t('notifications')"
        :to="{ name: 'notification_groups' }"
        svg="bell"
        v-if="$can('manage_notification_groups')"
      />
      <nav-link
        :label="$t('options')"
        :to="{ name: 'organisation' }"
        svg="cog"
        v-if="$can('manage_options')"
      />
      <nav-link
        :label="$t('support')"
        :to="{ name: 'support' }"
        svg="support"
        target="_blank"
        v-if="config.app.beta"
      >
        <p class="font-semibold">{{ $t('support_report') }}</p>
      </nav-link>
    </template>
    <div class="flex items-center py-2">
        <app-svg class="mr-3 h-6 w-6 text-white" :svg="'logout'"/>
        <div class="w-full">
          <button class="font-semibold text-white" @click="logout" :label="$t('log_out')" >{{ $t('log_out') }}</button>
        </div>
    </div>
  </nav>
</template>

<script>
import Badge from '@/components/Badge.vue';
import config from '@/./config';
import NavLink from '@/components/NavLink.vue';
import { mapGetters } from 'vuex';
import ApiAuthService from '@/services/api/auth';
import { SET_USER } from '@/store/mutation-types';
import AppSvg from '@/components/AppSvg.vue';

export default {
  components: {
    Badge,
    NavLink,
    AppSvg,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    actionReqTheme() {
      return 'error';
    },
    config() {
      return config;
    },
  },
  data() {
    return {
      actionReqCount: null,
    };
  },
  mounted() {
    this.getActionReqCount();
  },
  methods: {
    getActionReqCount() {
      // ...
    },
    logout() {
      ApiAuthService.logout().then(() => {
        this.$store.commit(`auth/${SET_USER}`, null);
        this.$router.push({
          name: 'login',
        }).catch(() => {});
      });
    },
  },
};
</script>
