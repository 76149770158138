export default function permissions({
  to,
  next,
}) {
  if (to.meta.permissions) {
    // ...

    return next(false);
  }

  return next();
}
