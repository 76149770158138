import _ from 'lodash';

class Errors {
  constructor(errors) {
    this.errors = errors;
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  clear(field) {
    if (field) {
      delete this.errors[field];

      return;
    }

    this.errors = {};
  }

  setErrors(errors) {
    this.errors = errors;
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }

    return false;
  }

  flatten() {
    return _.flatten(_.values(this.errors));
  }
}

export default Errors;
