<template>
  <div class="h-screen flex overflow-hidden bg-app-black">
    <div class="md:block md:flex md:flex-shrink-0 bg-gradient absolute md:static z-20 md:z-10"
      :class="{
        'hidden': !showMobileMenu,
      }"
    >
      <div class="flex flex-col w-64">
        <div class="flex flex-col h-0 flex-1">
          <div class="flex-1 flex flex-col py-10 overflow-y-auto">
            <div class="flex items-center flex-shrink-0 px-8">
              <logo class="h-10 w-auto"/>
            </div>
            <app-nav class="mt-5 flex-1 px-8 text-sm" :key="user.id" v-if="user" />
          </div>
          <nav-account :key="user.id" v-if="user"/>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden bg-app-white z-10 md:z-20">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 shadow z-10">
        <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-app-orange"
          @click="toggle"
        >
          <app-svg class="h-6 w-6" svg="outline/menu"/>
        </button>
      </div>
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none py-6 px-4 sm:px-6 md:px-8">
        <div class="fixed inset-0 bg-app-black bg-opacity-75 z-10" v-show="showMobileMenu"
          @click="toggle"
        ></div>
        <slot/>
      </main>
    </div>
  </div>
</template>

<script>
import AppNav from '@/components/Nav.vue';
import AppSvg from '@/components/AppSvg.vue';
import Logo from '@/components/Logo.vue';
import NavAccount from '@/components/NavAccount.vue';
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters({
    user: 'auth/user',
  }),
  components: {
    AppSvg,
    AppNav,
    Logo,
    NavAccount,
  },
  data() {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    toggle() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
  mounted() {
    // console.log('layouts/Default.vue');
  },
};
</script>
