import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import qs from 'qs';
import config from '@/config';

import middlewarePipeline from '@/router/middleware-pipeline';
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import permissions from '@/middleware/permissions';

const globalMiddleware = [
  auth,
  permissions,
];

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: () => import(/* webpackChunkName: "not_found" */ '../views/NotFound.vue'),
    meta: {
      layout: 'Guest',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      layout: 'Guest',
      middleware: [
        guest,
      ],
    },
  },
  {
    path: '/',
    redirect: {
      name: 'dashboard',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/assets',
    name: 'assets',
    component: () => import(/* webpackChunkName: "assets" */ '../views/Assets/Index.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/assets/:assetId/edit',
    name: 'assets.edit',
    component: () => import(/* webpackChunkName: "assets.edit" */ '../views/Assets/Edit.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/asset-categories',
    name: 'asset_categories',
    component: () => import(/* webpackChunkName: "asset_categories" */ '../views/AssetCategories.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/asset-reports',
    name: 'asset_reports',
    component: () => import(/* webpackChunkName: "asset_reports" */ '../views/AssetReports/Index.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/asset-reports/create',
    name: 'asset_reports.create',
    component: () => import(/* webpackChunkName: "asset_reports.create_edit" */ '../views/AssetReports/CreateEdit.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/asset-reports/:assetReportId/edit',
    name: 'asset_reports.edit',
    component: () => import(/* webpackChunkName: "asset_reports.create_edit" */ '../views/AssetReports/CreateEdit.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/organisation',
    name: 'organisation',
    component: () => import(/* webpackChunkName: "organisation" */ '../views/Organisation.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users/Index.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: () => import(/* webpackChunkName: "users.create_edit" */ '../views/Users/CreateEdit.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/users/:userId/edit',
    name: 'users.edit',
    component: () => import(/* webpackChunkName: "users.create_edit" */ '../views/Users/CreateEdit.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import(/* webpackChunkName: "roles" */ '../views/Roles.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/notification-groups',
    name: 'notification_groups',
    component: () => import(/* webpackChunkName: "notification_groups" */ '../views/NotificationGroups.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/support',
    name: 'support',
    beforeEnter() {
      window.location.replace(config.app.supportUrl);
    },
    meta: {
      layout: 'Default',
    },
  },
  // Master
  {
    path: '/master/organisations',
    name: 'master_organisations',
    component: () => import(/* webpackChunkName: "master_organisations" */ '@/views/Master/Organisation/Index.vue'),
    meta: {
      layout: 'Default',
    },
  },
  {
    path: '/master/organisation/:organisationId/edit',
    name: 'master_organisations.edit',
    component: () => import(/* webpackChunkName: "master_organisations.edit" */ '@/views/Master/Organisation/Edit.vue'),
    meta: {
      layout: 'Default',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  // https://github.com/vuejs/vue-router/issues/1259#issuecomment-306000548
  parseQuery(query) {
    return qs.parse(query, {
      comma: true,
    });
  },
  stringifyQuery(query) {
    const result = qs.stringify(query, {
      arrayFormat: 'comma',
      encode: false,
    });

    return result ? `?${result}` : '';
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch('validation/clearAllValidationErrors');

  const middleware = to.meta.middleware || globalMiddleware;

  if (!middleware || middleware.length === 0) {
    return next();
  }

  const context = {
    to,
    from,
    store,
    next,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

/* Replaced by https://vue-meta.nuxtjs.org/
router.afterEach((to) => {
  let metaTitle = i18n.t(`meta.titles.${to.name}`, {});
  metaTitle = `${metaTitle} | ${config.app.name}`;

  document.title = metaTitle;
});
*/

// https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
router.onError((error) => {
  console.log('router.onError()');

  if (/Loading chunk \d* failed./i.test(error.message)) {
    // ...
  }
});

export default router;
