<template>
  <div class="flex-shrink-0 flex p-4 bg-app-green-darkest">
    <router-link class="flex-shrink-0 w-full group block"
      :to="{ name: 'account' }"
      exact
    >
      <div class="flex items-center">
        <div class="inline-block relative">
          <app-svg class="h-10 w-10 text-gray-300" svg="user"/>
          <span class="absolute bottom-0 right-0 block h-1.5 w-1.5 ring-2 ring-white bg-app-green account-status"></span>
        </div>
        <div class="ml-3 text-sm">
          <p class="text-white font-semibold truncate leading-3" v-if="user.organisation">{{ user.organisation.name }}</p>
          <p class="text-white truncate">{{ user.name ? user.name : user.username }}</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppSvg,
  },
  computed: mapGetters({
    user: 'auth/user',
  }),
};
</script>
