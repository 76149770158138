import axios from 'axios';
import appConfig from '@/config';
import router from '@/router';
import store from '@/store';
import { SET_USER, START_LOADING, FINISH_LOADING } from '@/store/mutation-types';
import qs from 'qs';
import i18n from '@/i18n';

const http = axios.create({
  baseURL: appConfig.api.baseUrl,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, {
    arrayFormat: 'comma',
  }),
});

// https://github.com/axios/axios#interceptors
http.interceptors.request.use((config) => {
  store.commit(`loading/${START_LOADING}`);

  return config;
}, (error) => {
  store.commit(`loading/${FINISH_LOADING}`);

  return Promise.reject(error);
});

http.interceptors.response.use((response) => {
  store.commit(`loading/${FINISH_LOADING}`);

  const { config } = response;

  if (config.showMessage) {
    let message;

    if (config.formId && i18n.te(`messages.forms.success.${config.formId}`)) {
      message = i18n.t(`messages.forms.success.${config.formId}`, {});
    } else {
      message = i18n.t('messages.forms.success.default', {});
    }

    store.dispatch('messages/addMessage', {
      // id: 'formId' in config ? `app_response_2xx_${config.formId}` : 'app_response_2xx',
      type: 'success',
      message,
    });
  }

  if (config.formId) {
    store.dispatch('validation/clearValidationErrors', {
      namespace: response.config.formId,
    });
  }

  return response;
}, (error) => {
  store.commit(`loading/${FINISH_LOADING}`);

  const { config, data, status } = error.response;
  if ([
    401, // Auth
    419, // CSRF
  ].includes(status)) {
    store.commit(`auth/${SET_USER}`, null);

    router.push({
      name: 'login',
    }).catch(() => {});
  }

  if (status === 403) {
    store.dispatch('messages/addMessage', {
      id: 'app_response_403',
      type: 'warning',
      message: i18n.t('app_response_403'),
    });
  }

  if (status === 429) {
    store.dispatch('messages/addMessage', {
      id: 'app_response_429',
      type: 'warning',
      message: i18n.t('app_response_429'),
    });
  }

  if (status === 500) {
    store.dispatch('messages/addMessage', {
      id: 'app_response_500',
      type: 'error',
      message: appConfig.env !== 'development' || !data.message ? i18n.t('app_error') : data.message,
    });
  }

  if (status === 422) {
    if (config.showMessage) {
      let message;

      if (config.formId && i18n.te(`messages.forms.error.${config.formId}`)) {
        message = i18n.t(`messages.forms.error.${config.formId}`, {});
      } else {
        message = i18n.t('messages.forms.error.default', {});
      }

      store.dispatch('messages/addMessage', {
        // id: 'formId' in config ? `app_response_422_${config.formId}` : 'app_response_422',
        type: 'error',
        message,
      });
    }

    if (config.formId && data.errors) {
      store.dispatch('validation/setValidationErrors', {
        namespace: config.formId,
        errors: data.errors,
      });
    }
  } else {
    store.dispatch('validation/clearAllValidationErrors');
  }

  return Promise.reject(error);
});

export default http;
