import { START_LOADING, FINISH_LOADING } from '@/store/mutation-types';

export default {
  namespaced: true,
  state: {
    loading: 0,
  },
  getters: {
    loading(state) {
      return state.loading > 0;
    },
  },
  mutations: {
    [START_LOADING](state) {
      state.loading += 1;
    },
    [FINISH_LOADING](state) {
      state.loading -= 1;
    },
  },
};
