<template>
  <div class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0"
        v-for="(message, index) in messages"
        :key="index"
      >
        <div class="max-w-sm w-full bg-white shadow pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <app-svg class="h-6 w-6" :svg="getMessageIcon(message.type)" :class="getMessageClass(message.type)"/>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ message.message }}
                </p>
                <p class="mt-1 text-sm text-gray-500" v-if="message.message_help">
                  {{ message.message_help }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="removeMessage(index);" class="bg-white inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-app-orange">
                  <app-svg class="h-5 w-5" svg="x"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    AppSvg,
  },
  computed: {
    ...mapState('messages', [
      'messages',
    ]),
  },
  methods: {
    ...mapActions({
      removeMessage: 'messages/removeMessage',
    }),
    // eslint-disable-next-line no-unused-vars
    getMessageClass(type) {
      switch (type) {
        case 'error':
          return 'text-red-400';
        case 'info':
          return 'text-blue-400';
        case 'warning':
          return 'text-yellow-400';
        default:
          return 'text-green-400';
      }
    },
    // eslint-disable-next-line no-unused-vars
    getMessageIcon(type) {
      switch (type) {
        case 'error':
          return 'x-circle';
        case 'info':
          return 'information-circle';
        case 'warning':
          return 'exclamation-circle';
        default:
          return 'check-circle';
      }
    },
  },
};
</script>
