<!-- https://github.com/visualfanatic/vue-svg-loader/issues/51#issuecomment-431019099 -->
<template>
  <component :is="dynamicSvg" @click="$emit('click')"/>
</template>

<script>
export default {
  props: {
    svg: {
      type: String,
      required: true,
    },
  },
  computed: {
    dynamicSvg() {
      // https://github.com/babel/babel-eslint/issues/681#issuecomment-652141552
      // eslint-disable-next-line no-unused-expressions
      this.svg; // Trick to make reactive.

      // eslint-disable-next-line
      return () => import(/* webpackChunkName: "svgs" */ /* webpackMode: "lazy-once" */ '@/assets/svgs/' + this.svg + '.svg');
    },
  },
};
</script>
