import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import loading from '@/store/modules/loading';
import messages from '@/store/modules/messages';
import validation from '@/store/modules/validation';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    loading,
    messages,
    validation,
  },
});
