export default function guest({
  next,
  store,
}) {
  if (store.getters['auth/user']) {
    return next({
      name: 'dashboard',
      replace: true,
    });
  }

  return next();
}
