<template>
  <div>
    <slot/>
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log('layouts/Guest.vue');
  },
};
</script>
