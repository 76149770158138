import '@/assets/scss/app.scss';
import '@/mixins';
import '@/plugins/loading';
import * as Sentry from '@sentry/vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import ApiAuthService from '@/services/api/auth';
import App from '@/App.vue';
import config from '@/config';
import Default from '@/layouts/Default.vue';
import Guest from '@/layouts/Guest.vue';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { SET_USER } from '@/store/mutation-types';

if (config.sentry.env !== 'local') {
  Sentry.init({
    Vue,
    dsn: config.sentry.dsn,
    environment: config.sentry.env,
  });
}

Vue.config.productionTip = config.env !== 'development';
Vue.component('Default', Default);
Vue.component('Guest', Guest);

Vue.use(VueGoogleMaps, {
  load: {
    key: config.google.apiKey,
    libraries: 'places',
  },
});
Vue.use(VueMeta);

ApiAuthService.user({
  params: {
    include: [
      'organisation',
      'permissions',
    ],
  },
}).then((response) => {
  store.commit(`auth/${SET_USER}`, response.data.data);
}).catch(() => {
  store.commit(`auth/${SET_USER}`, null);
}).finally(() => {
  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
