import { ADD_MESSAGE, CLEAR_MESSAGES, REMOVE_MESSAGE } from '../mutation-types';

const messageExpirationSeconds = 5;

export default {
  namespaced: true,
  state: {
    messages: [],
  },
  mutations: {
    [ADD_MESSAGE](state, message) {
      if (state.messages.filter((m) => m.id && message.id && m.id === message.id).length) {
        return; // Message already exists.
      }

      state.messages.push(message);
    },
    [REMOVE_MESSAGE](state, index) {
      state.messages.splice(index, 1);
    },
    [CLEAR_MESSAGES](state) {
      state.messages = [];
    },
  },
  actions: {
    addMessage({
      commit,
    }, message) {
      commit(ADD_MESSAGE, message);

      setTimeout(() => {
        commit(REMOVE_MESSAGE, 0);
      }, messageExpirationSeconds * 1000);
    },
    removeMessage({
      commit,
    }, index) {
      commit(REMOVE_MESSAGE, index);
    },
    clearMessages({
      commit,
    }) {
      commit(CLEAR_MESSAGES);
    },
  },
};
