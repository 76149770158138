import Vue from 'vue';
import VueI18n from 'vue-i18n';

// https://github.com/kazupon/vue-i18n/issues/474#issuecomment-444817451
import en from './en.json';
import fr from './fr.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    fr,
  },
  silentTranslationWarn: true,
});
