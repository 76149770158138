import { SET_USER } from '../mutation-types';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    [SET_USER](state, payload) {
      state.user = payload;
    },
  },
};
