<template>
  <app-svg svg="logo"/>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';

export default {
  components: {
    AppSvg,
  },
};
</script>
