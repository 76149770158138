import _ from 'lodash';
import store from '@/store';
import Vue from 'vue';

Vue.mixin({
  methods: {
    $can: (permission) => {
      const userPermissions = _.get(store.getters['auth/user'], 'permission_names', []);

      return userPermissions.includes(permission);
    },
  },
});
