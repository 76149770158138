export default function auth({
  to,
  next,
  store,
}) {
  if (!store.getters['auth/user']) {
    return next({
      name: 'login',
      replace: true,
      query: {
        redirect: to.fullPath,
      },
    });
  }

  return next();
}
