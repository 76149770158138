<template>
  <router-link
    class="text-white"
    :to="to"
  >
    <div class="flex items-center py-2">
      <app-svg class="mr-3 h-6 w-6" :svg="svg" v-if="svg"/>
      <div>
        {{ label }}
        <slot/>
      </div>
    </div>
  </router-link>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';

export default {
  components: {
    AppSvg,
  },
  props: {
    label: {
      required: true,
      type: String,
    },
    svg: {
      type: String,
    },
    to: {
      type: Object,
    },
  },
};
</script>
