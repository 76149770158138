import axios from '@/plugins/axios';
import ApiCookieService from '@/services/api/cookie';

export default {
  async login(data, config = {}) {
    await ApiCookieService.getCsrfCookie();

    return axios.post('/v1/login', data, config);
  },

  user(config = {}) {
    return axios.get('/v1/user', config);
  },

  logout() {
    return axios.post('/v1/logout');
  },
};
