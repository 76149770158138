// https://vuex.vuejs.org/guide/mutations.html#using-constants-for-mutation-types
// auth
export const SET_USER = 'SET_USER';

// loading
export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';

// messages
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

// validation
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const CLEAR_VALIDATION_ERRORS = 'CLEAR_VALIDATION_ERRORS';
export const CLEAR_FIELD_VALIDATION_ERRORS = 'CLEAR_FIELD_VALIDATION_ERRORS';
export const CLEAR_ALL_VALIDATION_ERRORS = 'CLEAR_ALL_VALIDATION_ERRORS';
