export default {
  api: {
    baseUrl: process.env.VUE_APP_API_BASE_URL,
  },
  app: {
    beta: process.env.VUE_APP_BETA === 'true',
    name: process.env.VUE_APP_NAME,
    supportUrl: process.env.VUE_APP_SUPPORT_URL,
    googleMapDefaults: {
      center: {
        lat: 52.631523,
        lng: 1.287437,
      },
      zoom: 10,
    },
    pagination: {
      per_page: 25, // This setting is NOT used by all views.
    },
  },
  env: process.env.NODE_ENV,
  google: {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  },
  sentry: {
    env: process.env.VUE_APP_SENTRY_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
  },
};
