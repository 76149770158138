import NProgress from 'nprogress';
import store from '@/store';

// https://medium.com/@LoCascioNick/create-a-global-loading-progress-indicator-using-vuex-axios-and-nprogress-20451b33145a
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
});

store.watch(
  (state) => state.loading.loading,
  // eslint-disable-next-line consistent-return
  (newVal, oldVal) => {
    if (newVal === 0) {
      return NProgress.done();
    }
    if (oldVal === 0) {
      return NProgress.start();
    }
    NProgress.set(1.8 / Math.max(oldVal, newVal));
  },
);
