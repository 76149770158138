<template>
  <span class="inline-flex items-center px-2 py-0.5"
    :class="{
      'bg-blue-100 text-blue-800': theme === 'primary',
      'bg-green-100 text-green-800': theme === 'success',
      'bg-red-100 text-red-800': theme === 'error',
      'bg-yellow-100 text-yellow-800': ['warning', 'highlight'].includes(theme),
      'bg-gray-100 text-gray-800': theme === 'light',
    }"
    @click="$emit('click')"
  >
    {{ label }}
    <slot/>
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: [
        String,
        Number,
      ],
      required: true,
    },
    theme: {
      type: [
        String,
        Boolean,
      ],
      default: 'light',
    },
  },
};
</script>
